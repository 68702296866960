export default class FormCalculation {
  constructor(rootNode) {
    FormCalculation.once = rootNode.querySelector('.price-once')
    FormCalculation.monthly = rootNode.querySelector('.price-monthly')
    FormCalculation.yearly = rootNode.querySelector('.price-yearly')
    FormCalculation.form = rootNode.closest('form')
    FormCalculation.id = FormCalculation.form.getAttribute('name')
    FormCalculation.prices = rootNode.getAttribute('data-prices')
    FormCalculation.priceRows = FormCalculation.form.querySelectorAll('.fb-row[data-prices]:not(.fb-calculation)')

    FormCalculation.calculate()
    FormCalculation.form.addEventListener("change", function() {
      FormCalculation.calculate()
    })
  }

  /**
   * Calculate fields with prices
   */
  static calculate() {
    let calculated = {
      'once': 0,
      'monthly': 0,
      'yearly': 0
    }

    Object.entries(FormCalculation.priceRows).forEach(([key, row]) => {
      const prices = JSON.parse(row.getAttribute('data-prices'))
      calculated = FormCalculation.sumUp(prices, row, calculated);
    })

    Object.entries(JSON.parse(FormCalculation.prices)).forEach(([field, prices]) => {
      const row = FormCalculation.form.querySelector('[data-element-id$="_' + field + '"]')

      if(row) {
        calculated = FormCalculation.sumUp(prices, row, calculated);
      }
    })

    FormCalculation.once.innerHTML = FormCalculation.formatCurrency(calculated.once);
    FormCalculation.monthly.innerHTML = FormCalculation.formatCurrency(calculated.monthly);
    FormCalculation.yearly.innerHTML = FormCalculation.formatCurrency(calculated.yearly);
  }

  static sumUp(prices, row, calculated) {
    Object.entries(prices).forEach(([value, prices]) => {
      if(FormCalculation.hasValue(row, value)) {
        calculated.once += prices.once ? parseFloat(prices.once) : 0
        calculated.monthly += prices.monthly ? parseFloat(prices.monthly) : 0
        calculated.yearly += parseFloat(prices.yearly) ? parseFloat(prices.yearly) : 0
      }
    })

    return calculated
  }

  static formatCurrency(int) {
    return '€ ' + int.toFixed(2) // always two decimal digits
      .replace('.', ',') // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  /**
   * Check if input with given value is selected or checked
   * @param row
   * @param value
   * @returns {boolean}
   */
  static hasValue(row, value) {
    const input = row.querySelector('[value="' + value + '"]');

    if(!input) {
      return false
    }

    return (input.selected || input.checked)
  }
}