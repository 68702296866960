export default {
  slidesPerView: 1.1,
  breakpoints: {
    768: {
      slidesPerView: 2.2,
      spaceBetween: 20,
    },
    1680: {
      slidesPerView: 3.1,
      spaceBetween: 30,
    }
  },
}