import Swiper, { Pagination } from 'swiper/core'
import newsCarouselConfig from './slider/news-carousel'
import listIconLinksConfig from './slider/list-simple-icon'

Swiper.use([Pagination])

export default class Slider {
  constructor(rootNode, props) {
    const loadConfig = {
      'default': {},
      'newsCarousel': newsCarouselConfig,
      'iconLinks': listIconLinksConfig,
    }[props.config ?? 'default']

    const options = Object.assign({
      slidesPerView: 2,
      speed: 300,
      spaceBetween: 16,
      loadPrevNext: true,
      loadOnTransitionStart: true,
      // watchSlidesVisibility: true,
      pagination: {
        el: rootNode.querySelector('.swiper-pagination'),
        clickable: true,
      },
      preloadImages: false,
      lazy: true,
    }, loadConfig)

    new Swiper(rootNode, options)
  }
}
