export default class ProductGrid {
  constructor(rootNode) {
    this.$refs = {
      rootNode,
      items: [...rootNode.querySelectorAll('.ProductGridItem')],
    };

    this.$refs.items.forEach((item) => {
      const contentTrigger = item.querySelector('.ProductGrid-content-trigger');
      if (contentTrigger) {
        contentTrigger.addEventListener('click', this.toggleContent.bind(this));
      }
    });
  }

  toggleContent(event) {
    event.currentTarget.classList.toggle('open');
  }
}
