import 'jquery'
import 'jquery-depends-on'
import 'fine-uploader/jquery.fine-uploader/jquery.fine-uploader'
import 'jquery-pimcore-formbuilder/dist/dynamic-multi-file/jquery.fb.dmf.fine-uploader'
import 'jquery-pimcore-formbuilder/dist/jquery.fb.core'
import 'jquery-pimcore-formbuilder/dist/jquery.fb.ext.repeater';
import 'jquery-pimcore-formbuilder/dist/jquery.fb.ext.conditional-logic'
import CookieConsent from '@components/CookieConsent/CookieConsent'

window.dataLayer = window.dataLayer || [];

// Todo: Rewrite everything except formbuilder stuff to ES6
export default class Form {
  constructor(rootNode) {
    Form.root = rootNode
    Form.tabs = rootNode.querySelector('.fb-tabs')
    Form.messages = rootNode.querySelector('.fb-messages')
    Form.navigation = rootNode.querySelector('.fb-navigation')
    Form.tabsAnchor = rootNode.querySelector('.fb-tabs-anchor')
    Form.instance = $(Form.root).find('.formbuilder:not(.set_fb)')
    Form.rows = Form.instance.find('> .fb-row')
    Form.chapters = Form.instance.find('> .fb-tab-chapter')
    Form.summary = Form.instance.find('.fb-summary')
    Form.chaptersFieldsObj = []
    Form.chaptersObj = []
    Form.fieldsObj = []
    Form.returnChapter = null

    if (Form.prepare()) {
      Form.setup()
      Form.events()
    }
  }

  /**
   * Prepare custom logic
   */
  static prepare() {
    let current = Form.instance.find('.fb-tab-chapter').length > 0 ? null : 'simple'
    let stepI = 1

    if (current === 'simple') {
      Form.chaptersObj.push({
        id: 'simple',
        label: 'Simple',
        active: false,
      })
    }

    Form.rows.each(function() {
      let element = $(this)

      // Chapters
      if (element.hasClass('fb-tab-chapter')) {
        const id = element.attr('data-element-id')
        const label = element.attr('data-element-label')

        if (id && label) {
          current = id

          Form.chaptersObj.push({
            id: id,
            label: label,
            active: false,
          })

          stepI++
        }
      } else {
        const id = element.attr('data-element-id')

        if (id) {
          if (current) {
            if (Form.chaptersFieldsObj[current] === undefined) {
              Form.chaptersFieldsObj[current] = []
            }

            Form.chaptersFieldsObj[current].push(id)
            element.attr('data-chapter-id', current)
          }

          Form.fieldsObj.push({id: id})
        }
      }
    })

    if (Form.rows.length > 0) {
      return true
    }

    return false
  }

  /**
   * Setup
   */
  static setup() {
    // Init fb scripts
    Form.init()

    // Set ready state
    Form.instance.addClass('ready')

    // Start with first chapter
    Form.startChapter(Form.chaptersObj[0])
  }

  /**
   * Initialize formbuilder scripts
   */
  static init() {
    if (Form.instance.hasClass('ajax-form')) {
      Form.instance.formBuilderAjaxManager({
        setupFileUpload: true,
        resetFormMethod: function() {},
        dynamicMultiFileHandlerOptions: {
          defaultHandlerPath: null,
          libPath: null,
        },
        validationTransformer: {
          addValidationMessage: function($fields, messages) {
            Form.showError($fields, messages)
          },
          removeFormValidations: function($form) {
            Form.showError($form.find('.has-error'), false)
          }
        }
      })
    }

    $(document).formBuilderRepeater({
      classes: {
        add: 'tw-mt-5 IconLink has-plus-right',
        remove: 'tw-text-red-500 tw-text-sm',
      },
    })

    Form.instance.formBuilderConditionalLogic({
      elementTransformer: {
        show: function($els) {
          $els.each(function(key, el) {
            $(el).closest('.fb-row').removeClass('fb-hide')
          })
        },
        hide: function($els) {
          $els.each(function(key, el) {
            $(el).closest('.fb-row').addClass('fb-hide')
          })
        }
      },
    }).addClass('set_fb')

    Form.instance.on('formbuilder.dynamic_multi_file.init',
      function(ev, $dmfField, configuration) {
        configuration.addRemoveLinks = false
      }
    )

    Form.instance.on('formbuilder.success',
      function (ev, message, redirect, $form) {
        CookieConsent.trackEvent('form-sent', Form.getFormName())

        if (redirect) {
          document.location.href = redirect;
        } else {
          Form.showMessage((message && message[0] && message[0]['message'] ? message[0]['message'] : null), 'success')
        }
      }
    ).on('formbuilder.fail formbuilder.error',
      function (ev, messages, $form) {
        Form.showMessage(messages, 'error')
      }
    ).on('formbuilder.fatal',
      function (ev, response, $form) {
        Form.showMessage(response.error, 'error')
      }
    )

    // Multiple inputs solution
    Form.instance.find('.js-add-multi-input').
      click(function(e) {
        const list = $($(this).attr('data-list-selector'))
        let counter = list.data('widget-counter') || list.children().length
        let newWidget = list.attr('data-prototype')
        let removeTemplate = Form.instance.find('.fb-tmpl-remove-multi-input').html()

        newWidget = newWidget.replace(/__name__/g, counter)

        counter++

        list.data('widget-counter', counter)

        const newElem = $(list.attr('data-widget-tags')).html(newWidget + removeTemplate)
        Form.bindMultiInputEvents(newElem.find('.js-remove-multi-input'))
        newElem.appendTo(list)

        const collapse = $(this).closest('.fb-collapse')
        if (collapse.length > 0) {
          collapse.find('.item-button').trigger('click').trigger('click') // Todo: Cleaner solution after meeting
        }
      })

    Form.loadValues()
  }

  /**
   *
   * @param el
   */
  static bindMultiInputEvents(el) {
    if(!el) {
      return
    }

    el.click(function(e) {
      const list = $(this).closest('[data-widget-counter]');
      let counter = list.data('widget-counter');

      counter--;

      list.data('widget-counter', counter);

      $(this).closest('.multi-input-wrap').remove();
    });
  }

  /**
   * Events
   */
  static events() {
    $(Form.root).find('.fb-tab').click(function() {
      const chapter = Form.getChapter($(this).attr('data-chapter-id'))

      if (chapter) {
        Form.changeChapter(chapter)
      }
    })

    Form.instance.find('.fb-button-prev').click(function() {
      Form.goToChapter('prev')
    })

    Form.instance.find('.fb-button-next').click(function() {
      Form.goToChapter('next')
    })

    Form.instance.find('.fb-button-submit').click(function() {
      Form.instance.addClass('submitting')
    })

    Form.instance.on('change', function() {
      if (!Form.instance.data('started')) {
        CookieConsent.trackEvent('form-start', Form.getFormName())

        Form.instance.data('started', true)
      }

      Form.onChange()
    })

    Form.instance.on('submit', function() {
      Form.storeValues()
    })

    if (Form.tabs && Form.tabsAnchor) {
      document.addEventListener(
        'scroll',
        (event) => {
          if (Form.root.offsetTop
            && Form.tabsAnchor.offsetTop
            && Form.tabsAnchor.offsetTop !== Form.tabs.offsetTop
          ) {
            Form.tabs.classList.add('is-sticky')
          } else {
            Form.tabs.classList.remove('is-sticky')
          }
        },
        {passive: true},
      )
    }
  }

  /**
   * Start chapter and show questions
   * @param chapter
   */
  static startChapter(chapter) {
    if (Form.chaptersFieldsObj[chapter.id]) {
      const state = {'chapterId': chapter.id}
      const title = chapter.label
      const url = '#' + chapter.id
      const currentChapter = $('#fb-tab-chapter-' + chapter.id)

      history.pushState(state, title, url)

      $('.fb-tab-chapter').removeClass('active')
      Form.rows.removeClass('active')

      currentChapter.addClass('active')
      if(currentChapter.hasClass('fb-tab-chapter-summary')) {
        currentChapter.addClass('done')
      }

      $.each(Form.chaptersFieldsObj[chapter.id], function(k, v) {
        Form.instance.find('>.fb-row[data-element-id="' + v + '"]').addClass('active')
      })

      $.each(Form.chaptersObj, function(k, v) {
        Form.chaptersObj[k]['active'] = false

        if (v.id === chapter.id) {
          Form.chaptersObj[k]['active'] = true
        }
      })
    }

    if (Form.getNextChapter()) {
      Form.instance.addClass('show-next')
    } else {
      Form.instance.removeClass('show-next')
    }

    if (Form.getPreviousChapter()) {
      Form.instance.addClass('show-prev')
    } else {
      Form.instance.removeClass('show-prev')
    }

    if (Form.getCurrentChapter().id === Form.getLastChapter().id) {
      Form.instance.addClass('show-final')
    } else {
      Form.instance.removeClass('show-final')
    }
  }

  /**
   * Generate summary
   */
  static summarize() {
    Form.instance.find('.fb-summary-items').html('')

    Form.instance.find('.fb-row[data-element-label]').each(function() {
      const row = $(this)
      const label = row.attr('data-element-label')
      const parent = row.closest('.fb-row[data-chapter-id]')
      const chapterId = row.attr('data-chapter-id') ? row.attr('data-chapter-id') : parent.attr('data-chapter-id')
      const chapter = Form.summary.find('.fb-summary-chapter[data-chapter-id="' + chapterId + '"]')
      const value = Form.getValues(row, true, false)

      if (chapter) {
        chapter.removeClass('tw-hidden')
        chapter.find('.fb-summary-items').append(Form.generateItem(row, label, value))
      }
    })

    Form.summary.find('.fb-summary-items').each(function() {
      const chapterSummary = $(this).closest('.fb-summary-chapter')

      if($(this).find('.fb-summary-item').length === 0) {
        chapterSummary.addClass('tw-hidden')
      } else {
        chapterSummary.removeClass('tw-hidden')
      }
    })
  }

  /**
   * Get values
   *
   * @param row
   * @param direct
   * @param required
   * @returns {[]}
   */
  static getValues(row, direct = false, required = false) {
    const inputs = Form.getInputs(row, direct, required)

    return Form.getInputValues(inputs)
  }

  /**
   *
   * @returns {[]}
   * @param inputs
   */
  static getInputValues(inputs) {
    let value = []

    inputs.each(function() {
      if ($(this).attr('type') === 'radio'
        || $(this).attr('type') === 'checkbox') {
        let tmp = $(this).parent().find(':checked').parent().text()

        if(tmp && $(this).val() === '1') {
          tmp = 'Ja'
        }

        if (tmp) {
          value.push(tmp);
        }
      } else if ($(this).val() && $(this).val() !== '') {
        value.push($(this).val())
      }
    })

    return value
  }

  /**
   *
   * @param row
   * @param direct
   * @param required
   * @returns {jQuery}
   */
  static getInputs(row, direct = false, required = false) {
    let requiredAttr = ''
    let inputs

    if (required) {
      requiredAttr = ':required'
    }

    if (direct) {
      inputs = row.find('>input' + requiredAttr + ', >textarea' + requiredAttr + ', >select' + requiredAttr + ', >.tw-flex-row>.radio input' + requiredAttr + ', >.checkbox input' + requiredAttr)
    } else {
      inputs = row.find('input' + requiredAttr + ', textarea' + requiredAttr + ', select' + requiredAttr)
    }

    return inputs
  }

  /**
   *
   * @param row
   * @param label
   * @param value
   * @returns {*}
   */
  static generateItem(row, label, value) {
    let template = null

    if(value.length > 0) {
      template = $(Form.instance.find('.fb-tmpl-item').html())
    } else if(row.hasClass('fb-collapse') && Form.getValues(row, false, false).length > 0) {
      template = $(Form.instance.find('.fb-tmpl-headline').html())
    }

    if(template && label) {
      template.find('.label').html(label)

      if(value) {
        template.find('.value').html(value)
      }
    }

    return template
  }

  /**
   * Turn to prev chapter
   * @returns {boolean}
   * @param dir
   */
  static goToChapter(dir) {
    let chapter = null

    switch (dir) {
      case 'prev':
        chapter = Form.getPreviousChapter()
        break;

      case 'next':
        if (!Form.validateChapter('nextChapter')) {
          return false
        }

        if (Form.returnChapter) {
          Form.changeChapter(Form.returnChapter)
          Form.returnChapter = null
          return true
        }

        chapter = Form.getNextChapter()
        break
    }

    if (chapter) {
      Form.changeChapter(chapter)
    }

    return true
  }

  /**
   * Change chapter
   * @param newChapter
   */
  static changeChapter(newChapter) {
    const currentChapter = Form.getCurrentChapter()
    const questions = Form.chaptersFieldsObj[currentChapter.id]

    if (questions) {
      Form.root.scrollIntoView()
      $('.fb-row.active').removeClass('active')
      Form.startChapter(newChapter)
    }
  }

  /**
   * Return current active chapter
   * @returns {null}
   */
  static getCurrentChapter() {
    let chapter = null

    $.each(Form.chaptersObj, function(k, v) {
      if (v.active === true) {
        chapter = v
      }
    });

    return chapter
  }

  /**
   *
   * @param id
   * @returns {null}
   */
  static getChapter(id) {
    let chapter = null

    $.each(Form.chaptersObj, function(k, v) {
      if (v.id === id) {
        chapter = v
      }
    })

    return chapter
  }

  /**
   * Get last chapter
   */
  static getLastChapter() {
    let chapter = null

    $.each(Form.chaptersObj, function(k, v) {
      chapter = v
    });

    return chapter
  }

  /**
   * Turn to next chapter
   * @returns {boolean}
   */
  static getNextChapter() {
    const currentChapter = Form.getCurrentChapter()
    let chapter = null
    let selectNext = false

    $.each(Form.chaptersObj, function(k, v) {
      if (selectNext) {
        chapter = v
        return false
      }
      if (v.id === currentChapter.id) {
        selectNext = true
      }
    });

    return chapter
  }

  /**
   * Get previous chapter
   * @returns {null}
   */
  static getPreviousChapter() {
    const currentChapter = Form.getCurrentChapter()
    let chapter = null
    let selectNext = false

    $.each(Form.chaptersObj.slice().reverse(), function(k, v) {
      if (selectNext) {
        chapter = v
        return false
      }
      if (v.id === currentChapter.id) {
        selectNext = true
      }
    });

    return chapter
  }

  /**
   * Validate inputs
   * @param trigger
   * @returns {boolean}
   */
  static validateChapter(trigger) {
    const elements = Form.instance.find('>.fb-row.active').not('.fb-hide')
    const count = elements.length
    const chapter = Form.getCurrentChapter()

    let returnValue
    let completed = 0
    let scrollTo = null

    elements.each(function() {
      const row = $(this)

      if (Form.checkCondition(row)) {
        Form.showErrorRow(row, false)
        row.addClass('has-value')

        completed++
      } else {
        if (trigger === 'nextChapter') {
          Form.showErrorRow(row, true)

          if (!scrollTo) {
            scrollTo = row
          }
        }

        row.removeClass('has-value')
      }

      if(Form.instance.find('.has-error').length > 0) {
        Form.toggleSubInputErrors(row)
      }
    })

    if (completed >= count) {
      Form.setChapterStatus(chapter, 'done', true)

      returnValue = true
    } else {
      Form.setChapterStatus(chapter, 'done', false)

      if (trigger === 'nextChapter') {
        if (scrollTo[0]) {
          scrollTo[0].scrollIntoView()
        }
      }

      returnValue = false
    }

    return returnValue
  }

  /**
   *
   * @param row
   */
  static toggleSubInputErrors(row) {
    const requiredSubInputs = Form.getInputs(row, false, true)

    if(requiredSubInputs.length > 0) {
      requiredSubInputs.each(function() {
        const inputsRow = $(this).closest('.fb-row')

        if (!Form.checkCondition(inputsRow)) {
          inputsRow.addClass('has-error')
        } else {
          inputsRow.removeClass('has-error')
        }
      })
    }
  }

  /**
   * Show error
   * @param input
   */
  static showError(input, messages) {
    const row = input.closest('.fb-row[data-chapter-id]')

    if(row.length > 0) {
      const chapter = row.attr('data-chapter-id')

      Form.showErrorRow(row, true, messages);
      Form.changeChapter(Form.getChapter(chapter));
    }
  }

  /**
   *
   * @param row
   * @param bool
   */
  static showErrorRow(row, bool, messages = null) {
    const chapterRow = row.attr('data-chapter-id')

    if (bool) {
      row.addClass('has-error')

      if (row.find('.error-message').length === 0) {
        Form.showErrorRowMessage(row, bool, messages)
      }
    } else {
      row.removeClass('has-error')

      Form.showErrorRowMessage(row, bool, messages)
    }

    if(chapterRow) {
      Form.showErrorChapter(chapterRow)
    }
  }

  /**
   *
   * @param chapterId
   */
  static showErrorChapter(chapterId) {
    if(Form.tabs) {
      const chapterTab = Form.tabs.querySelector(
        '[data-chapter-id="' + chapterId + '"]').closest('li');
      const errors = Form.root.querySelectorAll(
        '.fb-row.has-error[data-chapter-id="' + chapterId + '"]')

      if (errors.length > 0) {
        chapterTab.classList.add('has-error')
      } else {
        chapterTab.classList.remove('has-error')
      }
    }
  }

  /**
   *
   * @param row
   * @param bool
   */
  static showErrorRowMessage(row, bool, messages = null) {
    const template = $(Form.instance.find('.fb-tmpl-error').html())

    if (bool) {
      row.append(template)

      if(messages) {
        row.find('.content').html(messages)
      }
    } else {
      row.find('.error-icon, .error-message').remove()
    }
  }

  /**
   *
   * @param row
   * @returns {boolean}
   */
  static checkCondition(row) {
    const requiredInputs = Form.getInputs(row, false, true)

    if (requiredInputs.length === 0) {
      return true
    }

    let inputArr = []
    let valueCount = 0

    requiredInputs.each(function() {
      inputArr.push($(this).attr('name'))

      if(Form.getInputValues($(this)).length > 0) {
        valueCount++
      }
    })

    inputArr = inputArr.filter((v, i, a) => a.indexOf(v) === i)

    return inputArr.length === valueCount
  }

  /**
   * Check changes directly
   */
  static onChange() {
    Form.validateChapter(null)

    // Summary
    Form.summarize()
  }

  /**
   * Set status class
   * @param chapter
   * @param status
   * @param value
   */
  static setChapterStatus(chapter, status, value) {
    const element = $('#fb-tab-chapter-' + chapter.id + ', #fb-summary-' + chapter.id)

    if (status && value) {
      element.addClass(status)
    } else if (status && !value) {
      element.removeClass(status)
    }
  }

  /**
   *
   * @param messages
   * @param type
   */
  static showMessage(messages, type) {
    let template
    let content = []

    Form.messages.innerHTML = ''

    if(typeof messages === 'string') {
      content.push(messages)
    } else if(messages.validation_errors) {
      Object.entries(messages.validation_errors).forEach(([key, message]) => {
        content.push(message)
      })
    }

    if(type === 'success') {
      template = Form.root.querySelector('.fb-tmpl-message-success').innerHTML

      Form.navigation.remove()
    } else {
      template = Form.root.querySelector('.fb-tmpl-message-error').innerHTML
    }

    if(template) {
      Form.messages.innerHTML = template
      Form.messages.querySelector('.content').innerHTML = content.join('<br/>')
    }
  }

  static storeValues() {
    if(!localStorage) {
      return false
    }

    const inputs = Form.root.querySelectorAll('[name]')
    const ignoredFields = [
      'formId',
      'formCl',
      'inputUserName',
      'formRuntimeData',
      'formRuntimeDataToken',
      '_token',
    ]
    const values = {}

    Object.entries(inputs).forEach(([key, input]) => {
      if (input.getAttribute('id')) {
        const fieldName = input.getAttribute('id').replace(Form.getFormName() + '_', '')

        if (
          fieldName.indexOf('field_') < 0
          && !ignoredFields.includes(fieldName)
          && input.value
        ) {
          let add = false

          if(['radio', 'checkbox'].includes(input.getAttribute('type'))) {
            if(input.checked) {
              add = true
            }
          } else {
            add = true
          }

          if(add) {
            values[fieldName] = input.value
          }
        }
      }
    })

    localStorage.setItem('swz_form_data', JSON.stringify(values));
  }

  static loadValues() {
    if(!localStorage) {
      return false
    }

    if(!localStorage.getItem('swz_form_data')) {
      return false
    }

    try {
      const values = JSON.parse(localStorage.getItem('swz_form_data'));

      Object.entries(values).forEach(([key, value]) => {
        const input = Form.root.querySelector('[id^="' + Form.getFormName() + '_' + key + '"]')

        if(input) {
          if(['radio', 'checkbox'].includes(input.getAttribute('type'))) {
            input.checked = true
          } else {
            input.value = value
          }
        }
      })
    } catch (e) {
      console.info(e)
    }
  }

  /**
   *
   * @returns {string}
   */
  static getFormName() {
    return Form.instance.attr('name')
  }
}