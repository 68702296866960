import TomSelect from 'tom-select'

export default class AvailabilityCheck {
  constructor(rootNode) {
    AvailabilityCheck.root = rootNode
    AvailabilityCheck.select = rootNode.querySelectorAll('.tom-select')

    AvailabilityCheck.initSelect();

    AvailabilityCheck.root.addEventListener("change", async function() {
      await AvailabilityCheck.checkAvailability()
    })
  }

  static async checkAvailability() {
    const available = await AvailabilityCheck.checkAddress()
    AvailabilityCheck.root.querySelector('[name*="[products]"]').value = null

    if(available.success && available.values) {
      Object.entries(available.values).forEach(([key, value]) => {
        AvailabilityCheck.root.querySelector('[name*="[products]"] option[value="'+value+'"]').selected = true
      })
    }

    AvailabilityCheck.root.querySelector('[name*="[products]"]').dispatchEvent(new Event('change'))
  }

  static async checkAddress() {
    const fields = AvailabilityCheck.root.querySelectorAll('[name*="[address]"]')
    const data = AvailabilityCheck.getFieldValues(fields)

    // Validate address
    if(data) {
      const response = await fetch('/form/check-availability', {
        method: 'POST',
        body: JSON.stringify(data)
      });

      return response.json()
    }

    return {success: false}
  }

  static getFieldValues(fields) {
    const values = []
    const required = AvailabilityCheck.root.querySelectorAll('[name*="[address]"][required]')

    Object.entries(fields).forEach(([key, field]) => {
      if(field.value) {
        values.push({'name': field.getAttribute('name'), 'value': field.value});
      }
    })

    return required.length === values.length ? values : null
  }

  static initSelect() {
    const inputSelects = []

    Object.values(AvailabilityCheck.select).forEach((select) => {
      const type = select.getAttribute('data-type');
      const settings = {
        valueField: 'name',
        labelField: 'name',
        searchField: 'name',

        load: function(query, callback) {
          const zip = inputSelects['zip'] ? inputSelects['zip'].getValue() : '';
          const url = '/api/address/' + type + '/' + encodeURIComponent(query) + '?zip=' + zip;

          fetch(url).then(response => response.json()).then(json => {
            callback(json.items);
          }).catch(() => {
            callback();
          });
        },
      };

      inputSelects[type] = new TomSelect('#' + select.getAttribute('id'), settings);
      inputSelects[type].on('change', async (value) => {
        switch(type) {
          case 'zip':
            if(value) {
              AvailabilityCheck.resetInputs(inputSelects['city'], false)
              AvailabilityCheck.resetInputs(inputSelects['street'], false)

              inputSelects['city'].enable();
              inputSelects['city'].focus()
            } else {
              AvailabilityCheck.resetInputs(inputSelects['city'], true)
              AvailabilityCheck.resetInputs(inputSelects['street'], true)
            }
            break;

          case 'city':
            if(value) {
              inputSelects['street'].enable();
              inputSelects['street'].focus()
            } else {
              AvailabilityCheck.resetInputs(inputSelects['street'], true)
            }
            break;

          case 'street':
            AvailabilityCheck.root.querySelector('[name*="[nummer]"]').removeAttribute('disabled')
            AvailabilityCheck.root.querySelector('[name*="[top]"]').removeAttribute('disabled')
            AvailabilityCheck.root.querySelector('[name*="[nummer]"]').focus()
            break;
        }
      })
    });
  }

  static resetInputs(input, disable = false) {
    input.setValue('')
    input.clearOptions()
    input.clearCache()

    if(disable) {
      input.disable()
    }
  }
}