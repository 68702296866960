import Cookies from 'js-cookie'

export default (editmode = false) => ({
  editmode,
  show: false,
  playAnimation: false,

  init() {
    const wrapperEl = this.$refs.wrapper // wraps text-elements
    const contentEl = this.$refs.content // contains text

    const wrapperBounds = wrapperEl.getBoundingClientRect()
    const contentBounds = contentEl.getBoundingClientRect()

    if (contentBounds.width > wrapperBounds.width) {
      const copyContent = contentEl.cloneNode(true)
      copyContent.removeAttribute('x-ref')

      wrapperEl.appendChild(copyContent)

      const textElements = [...wrapperEl.querySelectorAll('.text-content')]
      // set animation length based on inner-text length with a minimum of 10 seconds for all inner elements
      textElements.forEach((item) => {
        item.style.animationDuration = `${Math.max(10, (contentEl.innerText.length + 2) / 10).toFixed(0)}s`
      })

      this.playAnimation = true
    }

    if (!Cookies.get('stadtwerke-infobanner') || this.editmode) this.show = true
  },

  close() {
    if (this.editmode) return

    Cookies.set('stadtwerke-infobanner', true, { expires: 1/24 }) // expire in 1 hour
    this.show = false
  },
})