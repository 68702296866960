import { getCookies, setCookies } from './helper'

const win = window

export default class CookieConsent {
  constructor(rootNode) {
    this.$refs = {
      rootNode,
      bodyElement: document.body,
      toggleItems: [...rootNode.querySelectorAll('.script-list-item.is-toggable')],
      buttons: [...rootNode.querySelectorAll('.script-actions-btn')],
      checkboxes: [...rootNode.querySelectorAll('.CookiesSwitch input')]
    }

    this.cookies = getCookies()
    this.tagManagerId = rootNode.dataset.gtm
    this.isModal = rootNode.dataset.isModal || false

    // if no backend-config provided, stop
    if (!this.tagManagerId) return

    if (!this.cookies || !this.isModal) this.init()
    if (this.cookies && CookieConsent.hasAnyTrueValue()) {
      CookieConsent.enableGtm(this.tagManagerId)
      CookieConsent.fireCookieEvents()
    }
  }

  init() {
    if (this.isModal) this.openModal()
    else this.setCheckboxValues()

    this.setupInfoTogglers()
    this.setupBtnActions()
  }

  openModal() {
    this.$refs.bodyElement.classList.add('modal-active')
    this.$refs.rootNode.setAttribute('aria-hidden', false)
    this.$refs.rootNode.classList.add('show') // show modal
  }

  closeModal() {
    this.$refs.bodyElement.classList.remove('modal-active')
    this.$refs.rootNode.setAttribute('aria-hidden', true)
    this.$refs.rootNode.classList.remove('show') // show modal
  }

  setupInfoTogglers() {
    this.$refs.toggleItems.forEach((item) => {
      const btn = item.querySelector('.script-item-btn')

      btn.addEventListener('click', () => {
        item.classList.toggle('is-open')
      })
    })
  }

  setupBtnActions() {
    this.$refs.buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const action = button.dataset.action

        // get all script-ids and save cookie-values
        if (action === 'accept' || action === 'some') {
          // get object with 'scriptId': 'yes|no' values
          const cookies = this.$refs.checkboxes.reduce((cookies, checkbox) => {
            cookies[checkbox.name] = checkbox.checked || action === 'accept' ? 'yes' : 'no'

            return cookies
          }, {})

          // save cookies
          setCookies(cookies)

          // fire events based on cookies and enable gtm
          CookieConsent.enableGtm(this.tagManagerId)
          CookieConsent.fireCookieEvents()
        }

        if (this.isModal) this.closeModal()
      })
    })
  }

  // set checkbox values based on current cookies
  setCheckboxValues() {
    const cookies = getCookies()

    Object.keys(cookies).forEach((scriptId) => {
      const input = document.getElementById(`checkbox-${scriptId}`)

      if (scriptId === 'must') input.checked = true;
      else if (cookies[scriptId] === 'no') input.checked = false
      else if (cookies[scriptId] === 'yes') input.checked = true
    })
  }

  // loads script tag
  static enableGtm(tagManagerId) {
    CookieConsent.loadScript(tagManagerId)
  }

  // fire gtm events based on cookie-consent data
  static fireCookieEvents() {
    if (!CookieConsent.hasAnyTrueValue()) return

    const cookies = getCookies()

    Object.keys(cookies).forEach((scriptId) => {
      if (cookies[scriptId] === 'yes') {
        CookieConsent.trackEvent(scriptId, 'yes')
      }
    })

    CookieConsent.trackEvent('acc_consent_ready', 'yes')
  }

  // check if any cookie (script) has the value 'yes'
  static hasAnyTrueValue() {
    const cookies = getCookies()

    return Object.keys(cookies).some((k) => cookies[k] === 'yes')
  }

  // appends gtm script tag
  static loadScript(tagManagerId) {
    if (!tagManagerId) return

    (function(w, d, s, l, i) {
      w[l] = w[l] || []

      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js',
      })

      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''

      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', tagManagerId)
  }

  static trackEvent(event, value) {
    const dataLayer = win.dataLayer || []
    const tmp = {};
    tmp['event'] = event;
    tmp[tmp['event']] = 'yes';

    dataLayer.push(tmp)
  }
}
