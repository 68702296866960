import './components/alpine/index'
import * as components from './components'

// ACC namespace for global configs
const ACC = window.ACC || {
  vars: {},
  methods: {
    initializeComponents: () => {
      const componentNodes = Array.from(document.querySelectorAll('[data-component]')) || []

      componentNodes.forEach(node => {
        const componentName = node.getAttribute('data-component')

        let props = node.getAttribute('data-props')
        if (props) props = JSON.parse(props)

        /* eslint-disable no-new */
        new components[componentName](node, props)
      })
    },

    setViewportHeightInCss: () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
  },
}

// load/initialize complex js-components
document.addEventListener('DOMContentLoaded', () => ACC.methods.initializeComponents())

// TODO: add debouncer if performance issues occur
window.addEventListener('resize', ACC.methods.setViewportHeightInCss)
ACC.methods.setViewportHeightInCss()
